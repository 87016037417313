import React, { useCallback, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useResetRecoilState, useSetRecoilState } from "recoil";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { clearLocalStorageForForwardingShipdaWeb } from "@sellernote/_shared/src/services/browserStorage";
import { USER_ATOMS } from "@sellernote/_shared/src/states/common/user";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";

import { useTranslation } from "../../../utils/i18n";

export default function useLogout() {
  const { t } = useTranslation(["common-new"]);

  const [opensModal, setOpensModal] = useState(false);

  const setLoggedIn = useSetRecoilState(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const resetUserTeamAuthorityInfo = useResetRecoilState(
    USER_ATOMS.USER_TEAM_AUTHORITY_INFO
  );

  const router = useRouter();

  const handleLogout = useCallback(async () => {
    setOpensModal(false);

    await router.push("/");

    clearLocalStorageForForwardingShipdaWeb();
    window.sessionStorage.clear();

    setLoggedIn(false);
    resetUserTeamAuthorityInfo();

    window.location.reload();
  }, [router, setLoggedIn, resetUserTeamAuthorityInfo]);

  const handleConfirmLogoutModalOpen = () => setOpensModal(true);

  const handleConfirmLogoutModalClose = () => setOpensModal(false);

  const ConfirmLogoutModal = useMemo(
    () => (
      <Modal
        uiType="titleOnly"
        active={opensModal}
        title={t("common-new:헤더_로그아웃_안내")}
        actionPositive={{
          label: t("common-new:헤더_로그아웃_확인_버튼"),
          handleClick: handleLogout,
        }}
        actionNegative={{
          label: t("common-new:헤더_로그아웃_취소_버튼"),
          handleClick: handleConfirmLogoutModalClose,
        }}
      />
    ),
    [handleLogout, opensModal, t]
  );

  return { ConfirmLogoutModal, handleConfirmLogoutModalOpen, handleLogout };
}
